'use client'; // Ensures this runs on the client side

import {useSession} from "next-auth/react";
import {useEffect, useRef} from "react";
import analytics from "@/utils/analytics";

export const AuthObserver: React.FC = () => {
    const {data: session, status} = useSession();
    const hasIdentified = useRef<boolean>(false);

    useEffect(() => {
        if (status === 'authenticated' && session?.user && !hasIdentified.current) {
            // Identify the user in Segment
            analytics.identify(session.user.trackingId);
            hasIdentified.current = true;
        } else if (status === 'unauthenticated' && hasIdentified.current) {
            // Reset analytics on sign-out
            analytics.reset();
            hasIdentified.current = false;
        }
    }, [status, session]);

    return null; // This component doesn't render anything
};