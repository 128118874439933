'use client'; // Ensures this runs on the client side

import { usePathname } from 'next/navigation';
import React, { useEffect } from 'react';
import { useAnalytics } from '@/context/AnalyticsContext';

const AnalyticsTracker: React.FC = () => {
  const pathname = usePathname();
  const analytics = useAnalytics();

  useEffect(() => {
    try {
      // Track a page view
      analytics.page({
        category: 'Page Navigation',
        interaction: 'Loaded',
        action: `Page Navigation - ${document.title}`,
        path: pathname,
      });
    } catch (error) {
      console.error('Analytics page tracking failed:', error);
    }
  }, [pathname, analytics]);

  return null; // This component doesn't render anything
};

export default AnalyticsTracker;
